export const DEPARTMENTTASKS = {
  accounts: [
    "Update Cash Book for Monthly Transactions on daily basis",
    "To Track the Company's Budget Forecast on a Daily Basis",
    "Track all Payments and Transactions Daily",
    "Ikeja Office Security Payment",
  ],

  "medical services": [
    "Match Patients to Doctors",
    "Ensure visits are carried out as at when due, reports are posted in a timely manner, prescribed drugs delivered and requested tests done and for those  Doctors whose visits cannot be held on appointment date, ensure they call the Elderlies involved and fix a convenient time.",
    "Ensure all Outstanding Visits are Carried Out",
    "Co-ordinate the Activities of Care Partners",
    "Manage Sponsor Needs",
  ],

  "elderly services": [
    "Call all Elderlies Visited during the week",
    "Visit and create a relationship with 1 church every week or provide 5 contact information every week",
  ],

  "client services": [
    "Attending to all sponsor requests that come up during the week",
    "Sending of Prescriptions to Sponsors",
    "Call Sponsors to Add Elderly during the week",
    "Followup on Outstanding payments during the week",
  ],

  admin: [
    "Manage the day to day operations of the GeroCare application and customer relationship management systems.",
    "Update payment records and assist colleagues with any necessary information they need to pull out from websites and applications",
    "Send daily visit report to Dr Ebi.",
  ],
};

export const INDIVIDUALTASKS = {
  "o.tobore@gerocare.org": [
    "Recruitment, orientation, and onboarding of new doctors. Matching new patients to doctors and reassigning old patients to doctors when needed",
    "Ensure on-demand and first visits are done in a timely manner or contact the sponsor/elderly personally if the doctor has not contacted elderly by the third working day for new patients.",
    "Coordinating and recruiting care partners and management of vendor payments.",
    "Manage all sponsor needs when called upon and manage all doctor needs and payments",
    "Supervise activities in the medical services department and oversee clinical needs of Gerocare projects",
  ],
  "e.feghabo@gerocare.org": [
    "Ensure all medical requests are fulfilled in a timely manner by actively engaging partners covering Lagos and liasing with Dr. Tobore for those outside lagos. Monitor medical requests deliveries and update delivery statuses daily on CRM",
    "Coordinate activities of all Gerocare registered pharmacy, laboratory, and physiotherapy partners",
    "Validate visit reports/treatment plans and follow-up the medical care of special cases",
    "Manage all seniors' needs and concerns when called upon.",
    "Handle teleconsultation requests, MEDRA teleconsultations, and follow-up SDG beneficiaries",
  ],
  "o.olamide@gerocare.org": [
    "Ensure all visits are carried out as at when due and reports submitted in a timely manner and for those Doctors whose visits can not be carried out to ensure that they contact the elderlies involved and fix a convenient time",
    "Send weekly reminders to all doctors who have visits during the week to call beneficiaries and schedule their appointments on the app and call doctors on the day of their visit",
    "Ensure close visit tracking and daily updates on missed visits. Prompt client services to notify seniors and sponsors on reasons for missed visits.",
    "Monitor corporate visits and resubscriptions and ensure doctors contact patients at least within 3 working days of payment to schedule appointments",
    "Handle teleconsultation requests, MEDRA teleconsultations, and follow-up SDG beneficiaries",
  ],
  "o.joy@gerocare.org": [
    "Call all elderlies visited",
    "Process all Caregiver Application",
    "Speak to Two People weekly",
    "Advertise Gerocare on my social media handles",
  ],
  "b.caleb@gerocare.org": [
    "Monitor and fix server errors",
    "Review and resolve incident reports tagged to the tech team",
    "Review error logs on sentry",
  ],
};
